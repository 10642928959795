/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the base and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {



                // JavaScript to be fired on all pages, after page specific JS is fired


                // JavaScript Document
                var sayThis = function (target) {
                    /*
                     console.log(target);
                     */
                };

                //Image Background Exchanger
                function set_cover_image(target) {
                    var ww = $(window).width(),
                        wh = $(window).height();

                    var tabletBreakpoint = 1200;
                    var mobileBreakpoint = 767;

                    $(target).each(function (index) {
                        var imgsrc = $(this).attr('data-desktopimg');


                        if (ww < tabletBreakpoint && ww > mobileBreakpoint) {


                            var tabletimgp = $(this).attr('data-tabletimgp');
                            if (tabletimgp) {
                                imgsrc = tabletimgp;
                                sayThis('Using Portrait Tablet Image');
                            }

                            if (ww > wh || !tabletimgp) {
                                var tabletimg = $(this).attr('data-tabletimg');
                                if (tabletimg) {
                                    imgsrc = tabletimg;
                                    sayThis('Using Landscape Tablet Image');
                                }
                            }
                        }
                        else if (ww < mobileBreakpoint) {

                            var mobileimgp = $(this).attr('data-mobileimgp');
                            sayThis("mobileimgp " + mobileimgp);

                            if (mobileimgp) {
                                imgsrc = mobileimgp;
                                sayThis('Using Portrait Mobile Image');
                            }

                            var mobileimg = $(this).attr('data-mobileimg');
                            sayThis("mobileimg " + mobileimg);

                            if (ww > wh || !mobileimgp) {
                                if (mobileimg !== "") {
                                    imgsrc = mobileimg;
                                    sayThis('Using Landscape Mobile Image');
                                }
                            }


                        }

                        if (imgsrc) {
                            if ($(this).css('background-image') !== imgsrc) {
                                $(this).css('background-image', 'url("' + imgsrc + '")');
                            }
                        }

                        if (imgsrc === $(this).attr('data-desktopimg')) {
                            sayThis('Using Desktop Image');
                        }
                    });
                }

                $(window).resize(function () {
                    set_cover_image('.bkg_container');
                });

                $(document).ready(function () {
                    set_cover_image('.bkg_container');
                });

            },
            finalize: function () {


                var headerMenu = (function () {
                    var mobileMenuActive = false;
                    var mobileBreakpoint = 1050;

                    var setMobileMenu = function () {
                        if (!mobileMenuActive) {
                            mobileMenuActive = true;

                            var menuContainer = $("<div class='mobile-menu'></div>"),
                                menuList = $('<div class="mobile__dropdown"></div>');


                            /* Add items to menu
                             ============================================================*/

                            //Adding Menu Items to menu
                            $("#menu-home").clone().appendTo(menuList); //Menu Links
                            $(".nav__contact-info").clone().appendTo(menuList); //Contact Info


                            /* End */


                            //Add the menu List
                            menuContainer.append(menuList);


                            $("body").append(menuContainer);


                            //Opens and closes mobile menu
                            $(".mobile__menuicon").off();
                            $(".mobile__menuicon").click(toggleMobileMenu);

                        }
                    };

                    var removeMobileMenu = function () {

                        //Remove any open classes
                        $(".mobile-menu").removeClass("open");
                        $(".mobile__menuicon").removeClass("open");
                        $("body").removeClass("mobile-menu-open");

                        //Destory Mobile Menu
                        $(".mobile-menu").remove();

                        //Reset Variable to say menu isn't set
                        mobileMenuActive = false;
                    };


                    var toggleMobileMenu = function () {
                        $(".mobile-menu").toggleClass("open");
                        $(".mobile__menuicon").toggleClass("open");
                        $("body").toggleClass("mobile-menu-open");
                    };

                    var checkMobileMenu = function () {
                        var windowWidth = window.innerWidth;

                        //Checks if it's in the mobile view
                        var newMobileMenuState = windowWidth <= mobileBreakpoint;

                        //Checks if new mobile state is different to the old
                        if (newMobileMenuState !== mobileMenuActive) {
                            if (newMobileMenuState) {
                                setMobileMenu();
                            } else {
                                removeMobileMenu();
                            }
                        }
                    };


                    var init = function () {
                        var windowWidth = window.innerWidth;

                        if (windowWidth <= mobileBreakpoint) {
                            setMobileMenu();
                        }

                    };

                    return {
                        init: init,
                        checkMobileMenu: checkMobileMenu,
                    };
                })();


                /*Scrolls the browser to the top
                 ========================================= */
                function scrollToTop() {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 600);
                    return false;
                }

                $(".scroll-to-top").click(scrollToTop);


                /* Apply Classes On Scroll Height
                 ==============================================*/

                var lastScrollTop = 0;

                function onScroll() {
                    var scrollPos = $(document).scrollTop();
                    var windowWidth = window.innerWidth;


                    //If menu is at top show
                    if (scrollPos < 60 && windowWidth < 1050) {
                        $(".nav").addClass("top");
                    } else {
                        $(".nav").removeClass("top");
                    }

                    //If scroll is going up show
                    if (scrollPos < lastScrollTop) {
                        $(".nav").addClass("show");
                    } else {
                        $(".nav").removeClass("show");
                    }

                    //If scrolled down 150 show back to top
                    if (scrollPos > 150) {
                        $(".scroll-to-top").addClass("show");
                    }
                    else {
                        $(".scroll-to-top").removeClass("show");
                    }

                    lastScrollTop = scrollPos;
                }

                //Shrinks menu depending on mobile view.
                $(document).scroll(onScroll);
                onScroll();


                //Check if mobile menu is needed on window load and resize
                $(document).ready(headerMenu.init);
                $(window).resize(headerMenu.checkMobileMenu);



                /***
                 * Inline svg's with class .style-svg
                 */

                $(window).load(function () {

                // Polyfill to support all ye old browsers
                // delete when not needed in the future
                if (!String.prototype.endsWith) {
                    String.prototype.endsWith = function(searchString, position) {
                        var subjectString = this.toString();
                        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                            position = subjectString.length;
                        }
                        position -= searchString.length;
                        var lastIndex = subjectString.lastIndexOf(searchString, position);
                        return lastIndex !== -1 && lastIndex === position;
                    };
                } // end polyfill

                // Another snippet to support IE11
                String.prototype.endsWith = function(pattern) {
                    var d = this.length - pattern.length;
                    return d >= 0 && this.lastIndexOf(pattern) === d;
                };
                // End snippet to support IE11

                var target  = 'img.style-svg';

                $(target).each(function(index){
                    var $img = jQuery(this);
                    var imgID = $img.attr('id');
                    var imgClass = $img.attr('class');
                    var imgURL = $img.attr('src');

                    // Set svg size to the original img size
                    // var imgWidth = $img.attr('width');
                    // var imgHeight = $img.attr('height');

                    if (!imgURL.endsWith('svg')) {
                        return;
                    }

                    $.get(imgURL, function(data) {

                        // Get the SVG tag, ignore the rest
                        var $svg = $(data).find('svg');

                        var svgID = $svg.attr('id');

                        // Add replaced image's ID to the new SVG if necessary
                        if(typeof imgID === 'undefined') {
                            if(typeof svgID === 'undefined') {
                                imgID = 'svg-replaced-'+index;
                                $svg = $svg.attr('id', imgID);
                            } else {
                                imgID = svgID;
                            }
                        } else {
                            $svg = $svg.attr('id', imgID);
                        }

                        // Add replaced image's classes to the new SVG
                        if(typeof imgClass !== 'undefined') {
                            $svg = $svg.attr('class', imgClass+' replaced-svg svg-replaced-'+index);
                        }

                        // Remove any invalid XML tags as per http://validator.w3.org
                        $svg = $svg.removeAttr('xmlns:a');

                        // Add size attributes
                        // $svg = $svg.attr('width', imgWidth);
                        // $svg = $svg.attr('height', imgHeight);

                        // Replace image with new SVG
                        $img.replaceWith($svg);

                        $(document).trigger('svg.loaded', [imgID]);

                    }, 'xml');
                });
                });

            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                $(document).ready(function () {

                    /* Intro Slider
                     ================================================ */

                    $('.intro-slider').slick({
                        fade: true,
                        dots: true,
                        prevArrow: ".intro-slider__nav--prev",
                        appendDots: ".intro-slider__nav--dots",
                        nextArrow: ".intro-slider__nav--next",
                        autoplay: true,
                        autoplaySpeed: 4000,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    autoplay: false,
                                    swipe: false,
                                    touchMove: false
                                }
                            },
                        ]
                    });


                    /* Options Slider
                     ================================================ */

                    //Attaches event before slider is initialised
                    $('.options-slider').on('init', function (event, slick, direction) {
                        var windowWidth = $(window).width();

                        if (windowWidth > 1050) {
                            $(slick.$slides[0]).addClass("selected");
                        }
                    });

                    $('.options-slider').slick({
                        infinite: true,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        waitForAnimate: false,
                        prevArrow: ".options-slider__nav--prev",
                        appendDots: ".options-slider__nav--dots",
                        nextArrow: ".options-slider__nav--next",
                        responsive: [
                            {
                                breakpoint: 1050,
                                settings: {
                                    asNavFor: '.option-slider__info',
                                    slidesToShow: 1,
                                    waitForAnimate: true
                                }
                            }
                        ]
                    });


                    $('.option-slider__info').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        waitForAnimate: false,
                        responsive: [
                            {
                                breakpoint: 1023,
                                settings: {
                                    asNavFor: '.options-slider',
                                    waitForAnimate: true
                                }
                            }
                        ]
                    });

                    //Sets slider when the other is hovered over
                    $(".options-slider .slick-slide").mouseover(
                        function () {
                            var windowWidth = $(window).width();

                            if (windowWidth > 767) {
                                var slideIndex = $(this).attr("data-slick-index");
                                //Remove all selected classes
                                $(".options-slider .slick-slide").removeClass("selected");

                                $(this).addClass("selected");

                                $('.option-slider__info').slick("slickGoTo", slideIndex);
                            }
                        }
                    );


                    /* Quote Slider
                     ================================================ */

                    // On before slide change
                    $('.quote-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                        var nextSlideHeight = $(slick.$slides[nextSlide]).height();
                        $(".quote-slider__nav").css("top", nextSlideHeight + 10);
                    });

                    // On slider size change
                    $('.quote-slider').on('setPosition', function (event, slick) {
                        var currentSlideHeight = $(slick.$slides[slick.currentSlide]).height();
                        $(".quote-slider__nav").css("top", currentSlideHeight + 10);
                    });

                    // On slide init
                    $('.quote-slider').on('init', function (event, slick) {
                        var currentSlideHeight = $(slick.$slides[0]).height();
                        $(".quote-slider__nav").css("top", currentSlideHeight + 10);
                    });


                    $('.quote-slider').slick({
                        fade: true,
                        dots: false,
                        autoplay: false,
                        prevArrow: ".quote-slider__nav--prev",
                        nextArrow: ".quote-slider__nav--next",
                        responsive: [
                            {
                                breakpoint: 1023,
                                settings: {
                                    autoplay: false,
                                    swipe: false,
                                    touchMove: false,
                                    arrows: false
                                }
                            }
                        ]
                    });


                    /* Activate Parallax Effects
                     ================================================ */
                    // init controller
                    var controller = new ScrollMagic.Controller();
                    var packAndProtect = $('.pack-and-protect')[0];
                    var desktopHit;
                    var changeState = false;

                    //Set scene for parallax effect
                    var slideParallaxScene = new ScrollMagic.Scene({
                        triggerElement: packAndProtect,
                        triggerHook: 1,
                        duration: "180%"
                    })
                        .setTween(TweenMax.from(packAndProtect, 1, {
                            backgroundPosition: "50% 100%",
                            ease: Linear.easeNone
                        }));

                    //Checks window width and destroys the paralax if drops below tablet landscape
                    function checkParallaxEffect() {
                        var windowWidth = $(window).width();
                        desktopHit = windowWidth >= 1050;

                        //Check that the state has change so it doesn't spam the plugin
                        if (changeState !== desktopHit) {
                            changeState = desktopHit;
                            if (desktopHit) {
                                //Attach Scene to packAndProtect
                                slideParallaxScene.addTo(controller);
                            } else {
                                slideParallaxScene.remove();
                            }
                        }
                    }

                    $(window).resize(checkParallaxEffect);
                    $(document).ready(checkParallaxEffect);

                });

            }
        },
        // About us page, note the change from about-us to about_us.
        'about': {
            init: function () {
                // JavaScript to be fired on the about us page


                /* Content Slider
                 ================================================ */

                $('.content-slider__slider').slick({
                    fade: true,
                    dots: true,
                    prevArrow: ".content-slider__nav--prev",
                    appendDots: ".content-slider__nav--dots",
                    nextArrow: ".content-slider__nav--next",
                    asNavFor: '.content-slider__slider__img',
                });

                $('.content-slider__slider__img').slick({
                    fade: true,
                    dots: false,
                    arrows: false,
                    asNavFor: '.content-slider__slider',
                });


                /* Options Slider
                 ================================================ */

                //Attaches event before slider is initialised
                $('.options-slider').on('init', function (event, slick, direction) {
                    var windowWidth = $(window).width();

                    if (windowWidth >= 1030) {
                        $(slick.$slides[0]).addClass("selected");
                    }
                });

                $('.options-slider').slick({
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    prevArrow: ".options-slider__nav--prev",
                    appendDots: ".options-slider__nav--dots",
                    nextArrow: ".options-slider__nav--next",
                    waitForAnimate: false,
                    responsive: [
                        {
                            breakpoint: 1030,
                            settings: {
                                asNavFor: '.option-slider__info',
                                slidesToShow: 1,
                                waitForAnimate: true,
                            }
                        },
                    ]
                });


                $('.option-slider__info').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: true,
                    waitForAnimate: false,
                    responsive: [
                        {
                            breakpoint: 1030,
                            settings: {
                                asNavFor: '.options-slider',
                                waitForAnimate: true,
                            }
                        },
                    ]
                });


                //Sets slider when the other is hovered over
                $(".options-slider .slick-slide").mouseover(
                    function () {
                        var windowWidth = $(window).width();

                        if (windowWidth >= 1050) {
                            var slideIndex = $(this).attr("data-slick-index");
                            //Remove all selected classes
                            $(".options-slider .slick-slide").removeClass("selected");

                            $(this).addClass("selected");

                            $('.option-slider__info').slick("slickGoTo", slideIndex);
                        }
                    }
                );

                /* About Team Slider
                 ================================================ */

                $('.about-team-slider').slick({
                    fade: true,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    prevArrow: ".about-team-slider__nav--prev",
                    nextArrow: ".about-team-slider__nav--next"
                });


                /* Centered Icons Slider
                 ================================================ */

                $('.centered-icon-slider').slick({
                    infinite: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1050,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 770,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        },
                    ]
                });


                /* Twitter Slider
                 ================================================ */

                // On before slide change
                $('.twitter-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    var nextSlideHeight = $(slick.$slides[nextSlide]).height();
                    $(".twitter-slider__nav").css("top", nextSlideHeight - 55);
                });

                // On slider size change
                $('.twitter-slider').on('setPosition', function (event, slick) {
                    var currentSlideHeight = $(slick.$slides[slick.currentSlide]).height();
                    $(".twitter-slider__nav").css("top", currentSlideHeight - 55);
                });

                // On slide init
                $('.twitter-slider').on('init', function (event, slick) {
                    var currentSlideHeight = $(slick.$slides[0]).height();
                    $(".twitter-slider__nav").css("top", currentSlideHeight - 55);
                });


                $('.twitter-slider').slick({
                    fade: true,
                    dots: true,
                    prevArrow: ".twitter-slider__nav--prev",
                    appendDots: ".twitter-slider__nav--dots",
                    nextArrow: ".twitter-slider__nav--next",
                    autoplay: true,
                    autoplaySpeed: 4000,
                });

                /* Activate Parallax Effects
                 ================================================ */
                // init controller
                var controller = new ScrollMagic.Controller();
                var aboutMaterials = $('#about-materials')[0];
                var desktopHit;
                var changeState = false;

                //Set scene for parallax effect
                var slideParallaxScene = new ScrollMagic.Scene({
                    triggerElement: aboutMaterials,
                    triggerHook: 1,
                    duration: "180%"
                })
                    .setTween(TweenMax.from(aboutMaterials, 1, {
                        backgroundPosition: "50% 100%",
                        ease: Linear.easeNone
                    }));

                //Checks window width and destroys the paralax if drops below tablet landscape
                function checkParallaxEffect() {
                    var windowWidth = $(window).width();
                    desktopHit = windowWidth >= 1050;

                    //Check that the state has change so it doesn't spam the plugin
                    if (changeState !== desktopHit) {
                        changeState = desktopHit;
                        if (desktopHit) {
                            //Attach Scene to packAndProtect
                            slideParallaxScene.addTo(controller);
                        } else {
                            slideParallaxScene.remove();
                        }
                    }
                }

                $(window).resize(checkParallaxEffect);
                $(document).ready(checkParallaxEffect);

            }
        },
        'faqs': {

            finalize: function () {
                /* Packing Tips Slider
                 ================================================ */

                $('.packing-tips-slider').slick({
                    fade: true,
                    dots: false,
                    prevArrow: ".packing-tips-slider__nav--prev",
                    nextArrow: ".packing-tips-slider__nav--next"
                });

            }
        },
        'services': {

            finalize: function () {

                /* Quote Slider
                 ================================================ */

                // On before slide change
                $('.quote-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    var nextSlideHeight = $(slick.$slides[nextSlide]).height();
                    $(".quote-slider__nav").css("top", nextSlideHeight + 10);
                });

                // On slider size change
                $('.quote-slider').on('setPosition', function (event, slick) {
                    var currentSlideHeight = $(slick.$slides[slick.currentSlide]).height();
                    $(".quote-slider__nav").css("top", currentSlideHeight + 10);
                });

                // On slide init
                $('.quote-slider').on('init', function (event, slick) {
                    var currentSlideHeight = $(slick.$slides[0]).height();
                    $(".quote-slider__nav").css("top", currentSlideHeight + 10);
                });


                $('.quote-slider').slick({
                    fade: true,
                    dots: false,
                    prevArrow: ".quote-slider__nav--prev",
                    nextArrow: ".quote-slider__nav--next",
                    responsive: [
                        {
                            breakpoint: 1050,
                            settings: {
                                autoplay: false,
                                swipe: false,
                                touchMove: false,
                                arrows: false
                            }
                        },
                    ]
                });


            }
        },
        'page_template_page_servicessub': {

            finalize: function () {

                /* Centered Icons Slider
                 ================================================ */

                $('.centered-icon-slider').slick({
                    infinite: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1050,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 770,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        },
                    ]
                });

            }
        },
        'size_estimator': {

            finalize: function () {

                /* Intro Slider
                 ================================================ */

                $('.size_estimator-slider').slick({
                    fade: true,
                    dots: true,
                    arrows: false,
                    appendDots: ".size_estimator__nav--dots",
                    autoplay: true,
                    autoplaySpeed: 4000,
                });


            }
        },
        'contact': {

            finalize: function () {

            }
        }
    };

    // The routing fires all base scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire base init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire base finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
